:root {
  --red-blood: #a11616;
  --secondary-color: green;
  --red-bg: #b9222263;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Ringm';
  src: url('/public/RINGM__.TTF') format('truetype');
}

.menu-title {
  font-family: 'Ringm', Arial, sans-serif;
  color: red;
  text-shadow: 2px 2px 4px green;
}
